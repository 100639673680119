<template>
  <div>
    <div class="title">
      <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>任务结算
    </div>
    <tab1 ref="tab" v-if="flag.tab1flag" :orderId="orderBatchId" @Flagclick1="windowshow1"></tab1>
    <tab2 ref="tab" v-if="flag.tab2flag" :orderId="orderBatchId" @Flagclick2="windowshow2"></tab2>
    <tab3 ref="tab" v-if="flag.tab3flag" :url="url" :orderId="orderBatchId" @Flagclick3="windowshow3"></tab3>
  </div>
</template>

<script>
import tab1 from "./taskSet1.vue"
import tab2 from "./taskSet2.vue"
import tab3 from "./taskSet3.vue"
export default {
  data() {
   return{
     flag:{
         tab1flag:true,//显示
         tab2flag:false,
         tab3flag:false
     },
     orderBatchId:"",
     url:""
    
   }
  },
  components: {tab1,tab2,tab3},
  created() {},
  mounted(){  },
  activated() {
   this.flag.tab1flag=true
   this.flag.tab2flag=false
   this.flag.tab3flag=false
  },
  methods: {
   windowshow1(a,val){
    this.$forceUpdate();
      //  this.$set(this.flag,"tab1flag",a)
      this.flag.tab1flag=a
      this.flag.tab2flag=!a
      this.orderBatchId=val
   },
   windowshow2(a,b){
      this.$forceUpdate();
       this.flag.tab1flag= this.flag.tab2flag=a
      this.flag.tab3flag=!a
      this.url=b
   },
    windowshow3(a){
      this.$forceUpdate();
      this.flag.tab3flag= this.flag.tab2flag=a
      this.flag.tab1flag=!a
      
      
   }
  },
};
</script>

<style  scoped>
.title {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  background: #ffff;
  margin-top: -20px;
  margin-left: -20px;
  width: 105%;
  text-indent: 20px;
}
.icon {
  font-size: 30px;
  transform: translateY(5px);
  color: #b5b5b5;
  margin-right: 5px;
  cursor: pointer;
}
.main {
  margin-top: 10px;
}
</style>


